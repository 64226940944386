import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import faqs, { pokerfaqs } from '../../../components/FAQs/faqs-list'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { POKER, POKER_FAQS as POKERFAQS_LINK } from '../../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'
import PokerFAQ from '../../../components/FAQs/PokerFAQ'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Online Poker',
    url: POKER,
  },
  {
    title: 'Poker FAQs',
    url: POKERFAQS_LINK,
  },
]

const PokerFAQComp: React.FC = () => (
  <Layout
  showBreadcrumbs
  breadcrumbs={breadcrumbs}
  desktopBreadcrumbColor="black"
  pokerOneLink={pokerOneLinksStaticPage}
  rummyOneLink={rummyOneLinksStaticPage}
>
  <Helmet>
    {process.env.GATSBY_STAGING === 'true' && (
      <meta name="robots" content="noindex" />
    )}
  </Helmet>
  <SEO
    title="Poker FAQs: Frequently Asked Questions About Online Poker Game"
    description="Find answers to frequently asked questions about online poker. Learn poker rules, strategies, tips, and more in our detailed Poker FAQs guide."
    organizationSchema={generateOrgSchema()}
    websiteSchema={generateWebsiteSchema()}
    breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    faqSchema={generateFaqSchema(faqs)}
  />
    <PokerFAQ pokerfaqs={pokerfaqs}/>
  </Layout>
)

export default PokerFAQComp
